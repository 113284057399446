import { createStore } from 'vuex';

export default createStore({
  state: {
    showSideMenu: true
  },
  getters: {
  },
  mutations: {
    toggleSideMenu(state){
      state.showSideMenu = !state.showSideMenu
    }
  },
  actions: {
  },
  modules: {
  },
});
