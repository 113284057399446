<template>
    <section>
        <div class="container pt-40 pb-20 mx-auto">
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:flex-1">
                    <h6 class="font-medium text-indigo-600">บริการของเรา</h6>
                    <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">บริการที่ดีที่สุดสำหรับลูกค้าทุกท่าน</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor.</p>
                </div>
                <div class="w-full p-4 lg:w-auto">
                    <a href="#" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">เรียนรู้เพิ่มเติม</a>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div class="w-full p-4 xl:w-3/12 sm:w-6/12">
                    <a href="#" class="block px-6 py-16 bg-gray-200 rounded-lg shadow-lg hover:bg-indigo-400 hover:text-white"> <i class="mb-4 text-4xl text-indigo-700 fa-laptop-code fas"></i> <h5 class="mb-1 text-lg font-semibold">
                    Development </h5> <p class="text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor. </p> </a>
                </div>
                <div class="w-full p-4 xl:w-3/12 sm:w-6/12">
                    <a href="#" class="block px-6 py-16 bg-gray-200 rounded-lg shadow-lg hover:bg-indigo-400 hover:text-white sm:mt-16 sm:-mb-16"> <i class="mb-4 text-4xl text-indigo-700 fa-drafting-compass fas"></i> <h5 class="mb-1 text-lg font-semibold">
                    Product Design </h5> <p class="text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor. </p> </a>
                </div>
                <div class="w-full p-4 xl:w-3/12 sm:w-6/12" >
                    <a href="#" class="block px-6 py-16 bg-gray-200 rounded-lg shadow-lg hover:bg-indigo-400 hover:text-white"> <i class="mb-4 text-4xl text-indigo-700 fa-swatchbook fas"></i> <h5 class="mb-1 text-lg font-semibold">
                    UI/UX Research </h5> <p class="text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor. </p> </a>
                </div>
                <div class="w-full p-4 xl:w-3/12 sm:w-6/12" >
                    <a href="#" class="block px-6 py-16 bg-gray-200 rounded-lg shadow-lg hover:bg-indigo-400 hover:text-white sm:mt-16"> <i class="mb-4 text-4xl text-indigo-700 fa-network-wired fas"></i> <h5 class="mb-1 text-lg font-semibold">
                    Digital Marketing </h5> <p class="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor.</p> </a>
                </div>
            </div>
        </div>
    </section>
</template>