<template>
    <section>
        <div class="container pt-40 pb-20 mx-auto text-center">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">ผลงานของเรา</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae congue tortor. </p>
            </div>
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <a href="#" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/img-work-1.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-indigo-100">
                            <h5 class="text-base font-semibold text-gray-900">Project 1</h5>
                            <i class="fa-plus fas"></i>
                        </div> 
                    </a>
                </div>
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <a href="#" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/img-work-2.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-indigo-100">
                            <h5 class="text-base font-semibold text-gray-900">Project 2</h5>
                            <i class="fa-plus fas"></i>
                        </div> 
                    </a>
                </div>
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <a href="#" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/img-work-3.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-indigo-100">
                            <h5 class="text-base font-semibold text-gray-900">Project 3</h5>
                            <i class="fa-plus fas"></i>
                        </div> 
                    </a>
                </div>
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <a href="#" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/img-work-4.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-indigo-100">
                            <h5 class="text-base font-semibold text-gray-900">Project 4</h5>
                            <i class="fa-plus fas"></i>
                        </div> 
                    </a>
                </div>
            </div>
            <a href="#" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">ผลงานทั้งหมด</a>
        </div> 
    </section>
</template>