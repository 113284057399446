<template>
    <footer class="text-white bg-gray-900">
        <div class="container pt-20 mx-auto">
            <div class="flex flex-wrap">
                <div class="w-full p-4 xl:mr-auto xl:w-4/12">
                    <img src="@/assets/img/logo-main.png" class="w-48 mb-6" alt="smart logo" width="" height=""/>
                    <p class="mb-4 text-sm">Duis pharetra venenatis felis, ut tincidunt ipsum consequat nec. Fusce et porttitor libero, eu aliquam nisi. Nam finibus ullamcorper semper.</p>
                    <div class="mb-4">
                        <a href="#" class="hover:text-indigo-200">+1 234 567-890</a>
                        <br>
                        <a href="#" class="hover:text-indigo-200">hello@demo.com</a>
                    </div>
                    <div>
                        <a href="#" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-facebook-square fab"></i></a>
                        <a href="#" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-twitter-square fab"></i></a>
                        <a href="#" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-linkedin fab"></i></a>
                    </div>
                </div>
                <div class="w-full p-4 xl:w-2/12 sm:w-4/12">
                    <h4 class="mb-8 text-lg font-medium text-gray-400 uppercase">About</h4>
                    <ul>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Get Quote</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Enterprise Plan</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Become an Affiliate</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Our Portfolio</a>
                        </li>
                    </ul>
                </div>
                <div class="w-full p-4 xl:w-2/12 sm:w-4/12">
                    <h4 class="mb-8 text-lg font-medium text-gray-400 uppercase">Services</h4>
                    <ul>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Web Design</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Web Development</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">WordPress</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Digital Marketing</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Content Writing</a>
                        </li>
                    </ul>
                </div>
                <div class="w-full p-4 xl:w-2/12 sm:w-4/12">
                    <h4 class="mb-8 text-lg font-medium text-gray-400 uppercase">Other</h4>
                    <ul>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Business Stories</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Learning</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Licence</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Refund Policy</a>
                        </li>
                        <li class="mb-4">
                            <a href="#" class="hover:text-indigo-200">Terms of Service</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="p-4 text-center">
                <hr class="mb-4 opacity-25">
                <p class="text-sm">Copyright © 2020 Demo Inc.</p>
            </div>
        </div> 
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>