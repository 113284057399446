<template>
    <section class="bg-white">
        <div class="container py-20 mx-auto">
            <div class="flex flex-wrap items-center mt-16">
                <div class="order-last w-full p-4 lg:w-5/12 lg:order-first">
                    <h1 class="mb-4 text-5xl font-semibold leading-tight text-gray-800 capitalize">Smart Inventory system for your business growth </h1>
                    <p class="mb-4">ระบบคงคลังสินค้าที่จะมาช่วยเติมเต็มระบบของคุณให้เติบโตได้อย่างมั่นคง เริ่มต้นใช้งานระบบได้ฟรี</p>
                    <router-link to="/register" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">เริ่มต้นใช้งานฟรี</router-link>
                </div>
                <div class="order-first w-full p-4 lg:w-7/12 lg:order-last">
                    <img src="@/assets/img/img-banner.png" class="w-full" alt="Smart Inventory System" width="" height="" />
                </div>
            </div>
        </div>
    </section>
</template>