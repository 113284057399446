<template>
    <section>
        <div class="container pt-40 pb-20 mx-auto">
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:flex-1">
                    <h6 class="font-medium text-indigo-600">ติดต่อเรา</h6>
                    <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">มีคำถามติดเราได้ตลอดเวลาตามข้อมูลด้านล่าง</h2>
                    <p>โทร. 02-5879-525 อีเมล์ email@email.com</p>
                </div>
            </div>
        </div>
    </section>
</template>